<template>
  <div
    class="loading-screen bg-black absolute w-screen h-screen text-spring-wood top-0"
  >
    <div class="text-loading bg-black mt-56">
      <div class="text-loading text-4xl lg:text-7xl relative uppercase text-center">
        <div>Collection</div>
        <div>of <span class="crimson"> feelings </span></div>
        <!-- <span
          id="number-loading"
          class="number-loading text-xl absolute -top-6 left-2/3"
        >
          0
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
</style>