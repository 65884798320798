<template>
  <div
    id="nav"
    class="flex-wrap justify-between items-center p-6 hidden lg:flex overflow-y-hidden"
  >
    <router-link to="/" class="text-xl"> STUDIO-EC </router-link>
    <div class="flex">
      <div
        class="flex justify-between text-lg transition-all"
        v-for="(navigation, index) in navigations"
        :key="navigation.key"
      >
        <router-link
          :to="this.navigationsLinks[index]"
          class="link-navbar transition-all relative"
          >{{ navigation }}
          <span
            v-if="navigation === 'projects'"
            class="absolute text-sm -top-2"
          >
            ({{ this.projects.length }})</span
          >
        </router-link>
        <div
          class="mx-4 text-2xl font-bold"
          v-if="index != this.navigations.length - 1"
        >
          ,
        </div>
      </div>
    </div>
    <div class="container-circle relative">
      <div
        class="dark absolute rounded-full border border-black bg-black transition-all"
      ></div>
      <div
        class="white absolute rounded-full border border-black bg-spring-wood transition-all"
      ></div>
    </div>
  </div>
  <div
    id="navbar-phone"
    class="flex flex-wrap justify-between bg-spring-wood shadow p-6 fixed w-full top-0 z-50 lg:hidden"
  >
    <router-link to="/" id="eclipse" class="container-circle relative">
      <div
        class="dark absolute rounded-full border border-black bg-black"
      ></div>
      <div
        class="white absolute rounded-full border border-black bg-spring-wood"
      ></div>
    </router-link>
    <div
      v-on:click="toggleMenu"
      id="menu"
      v-bind:class="{ 'menu-opened': openedMenu }"
      class="z-999 lg:hidden container-menu"
    >
      <div class="bar-menu bar-1"></div>
      <div class="bar-menu bar-2"></div>
      <div class="bar-menu bar-3"></div>
    </div>
  </div>
  <div
    id="opened-phone-menu"
    class="bg-spring-wood fixed z-50 h-screen transition-all overflow-hidden top-20 lg:hidden"
    :class="openedMenu ? 'w-full ' : 'w-0 '"
  >
    <div class="phone-menu flex flex-col">
      <hr class="border border-black" />
      <router-link
        v-on:click="toggleMenu"
        to="/projects"
        class="px-6 py-3 text-3xl hover:text-spring-wood hover:bg-black"
        >PROJECTS
      </router-link>
      <hr class="border border-black" />
      <router-link
        v-on:click="toggleMenu"
        to="/about"
        class="px-6 py-3 text-3xl hover:text-spring-wood hover:bg-black"
        >ABOUT ME</router-link
      >
      <hr class="border border-black" />
      <router-link
        v-on:click="toggleMenu"
        to="/more"
        class="px-6 py-3 text-3xl hover:text-spring-wood hover:bg-black"
        >MORE</router-link
      >
      <hr class="border border-black" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Navbar",
  data() {
    return {
      openedMenu: false,
      loaded: true,
      navigations: ["projects", "about me", "more"],
      navigationsLinks: ["/projects", "/about", "/more"],
      projects: [],
    };
  },
  methods: {
    numberDisplay(final, id) {
      let div = document.getElementById(id);

      let start = 0;
      let end = final;
      let ticks = 20;
      let speed = 50;

      let randomNumbers = [end];

      for (let i = 0; i < ticks - 1; i++) {
        randomNumbers.unshift(
          Math.floor(Math.random() * (end - start + 1) + start)
        );
      }

      randomNumbers.sort((a, b) => {
        return a - b;
      });

      let x = 0;
      let interval = setInterval(function () {
        div.innerHTML = randomNumbers.shift();

        if (++x === ticks) {
          window.clearInterval(interval);
        }
      }, speed);
    },
    toggleMenu() {
      this.openedMenu = !this.openedMenu;
    },
  },
  async mounted() {
    const response = await axios.get(
      "https://portfolioController.r-selwa.space/api/projects/",
      // process.env.VUE_APP_PATH_API + "/api/projects/",
      {
        params: {
          status: "true",
        },
      }
    );

    this.projects = response.data.filter((project) => project.status == true);
  },
};
</script>

<style></style>
