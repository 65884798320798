<template>
  <div  class="sm:relative m-6 h-5/6 flex mt-24">
    <div class="landing-image fixed  top-1/2 translate-x-y left-1/2 h-1/2-screen sm:w-70-vmin xl:h-4/6 lg:w-4/6">
        <img v-bind:style="{'animation-delay':'0s'}" src="./../assets/raph-light.jpg" alt="" class="object-contain origin-center	opacity-0	  m-auto sm:pt-6 lg:pt-0 lg:object-cover  image-landing-animation  " />
    </div>

    <div class="flex flex-col z-2 uppercase w-full justify-between lg:text-12xl text-6xl landing-title h-5/6 ">
      <div v-bind:style="{'animation-delay':'0s'}" class="lg:flex-row flex-col flex lg:items-end landing-left opacity-0  " >
        studio
        <div class="lg:text-4xl text-xl sm:mt-2 ">
          freelance
          <br />
          front-end dev.
        </div>
      </div>

      <div  v-bind:style="{'animation-delay':'1.25s'}" class="text-right landing-right opacity-0 ">eclipse.</div>
    </div>
  </div>
  <div  v-bind:style="{'animation-delay':'0s'}" class="fixed bottom-12 left-12 hidden lg:block landing-left opacity-0 ">
    <div class="west">
    N <span id="northDegres">0</span> ° <span id="northMinutes">0</span>'<span id="northSeconds">0</span>"

  </div>
  <div class="west">
    W <span id="westDegres">0</span> ° <span id="westMinutes">0</span>'<span id="westSeconds">0</span>"
  </div>
  </div>
  <div v-bind:style="{'animation-delay':'1.25s'}" class=" text-xl fixed bottom-12 right-12 hidden lg:block landing-right opacity-0"> 2021</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data(){
    return {
       coordonates : [{ nD: 27, nM: 59, nS: 17, wD: 86, wM: 55, wS: 28 }],
      //  loading:false
    }
  },
 methods:{
   numberDisplay(final, id) {
      let div = document.getElementById(id);

      let start = 0;
      let end = final;
      let ticks = 100;//number of changing numbers init: 20
      let speed = 10;//interval between ticks init: 50

      let randomNumbers = [end];

      for (let i = 0; i < ticks - 1; i++) {
        randomNumbers.unshift(
          Math.floor(Math.random() * (end - start + 1) + start)
        );
      }

      randomNumbers.sort((a, b) => {
        return a - b;
      });

      let x = 0;
      let interval = setInterval(function () {
        div.innerHTML = randomNumbers.shift();

        if (++x === ticks) {
          window.clearInterval(interval);
        }
      }, speed);
    },
    coordonatesDisplay(ms){
setTimeout(() => {
  let x = this.coordonates[Math.floor(Math.random() * this.coordonates.length)];
  this.numberDisplay(x.nD, "northDegres");
  this.numberDisplay(x.nM, "northMinutes");
  this.numberDisplay(x.nS, "northSeconds");
  this.numberDisplay(x.wD, "westDegres");
  this.numberDisplay(x.wM, "westMinutes");
  this.numberDisplay(x.wS, "westSeconds");
}, ms);
    }
 },
 async mounted(){

this.coordonatesDisplay(2000)
setTimeout(() => {
  // this.loading = true
}, 2000);
 }
};
</script>
