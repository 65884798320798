<template>
  <LoadingScreen v-if="loaded"></LoadingScreen>
  <NavBar ></NavBar>
  
  <router-view />
  <div id="custom-cursor" class="custom-cursor bg-black rounded-full z-800 pointer-events-none lg:block hidden	"></div>
</template>

<script>
import NavBar from "./components/Navbar.vue";
import LoadingScreen from "./components/Loading.vue";
import axios from "axios"
export default {
  name: "App",
  components: {
    LoadingScreen,
    NavBar
  },
  data() {
    return {
      loaded: true,
      projects: [],
    };
  },
  methods: {
    numberDisplay(final, id) {
      let div = document.getElementById(id);

      let start = 0;
      let end = final;
      let ticks = 100;//number of changing numbers init: 20
      let speed = 10;//interval between ticks init: 50

      let randomNumbers = [end];

      for (let i = 0; i < ticks - 1; i++) {
        randomNumbers.unshift(
          Math.floor(Math.random() * (end - start + 1) + start)
        );
      }

      randomNumbers.sort((a, b) => {
        return a - b;
      });

      let x = 0;
      let interval = setInterval(function () {
        div.innerHTML = randomNumbers.shift();

        if (++x === ticks) {
          window.clearInterval(interval);
        }
      }, speed);
    },
  },
 async mounted() {

   //cursor
   document.body.addEventListener("mousemove",function(n){
     let cur = document.getElementById("custom-cursor");
 (cur.style.left = n.clientX + "px"), (cur.style.top = n.clientY + "px");
   })

const response = await axios.get( "https://portfolioController.r-selwa.space/api/projects/",{params:{
  // const response = await axios.get(process.env.VUE_APP_PATH_API + "/api/projects/",{params:{
    status:"true"
}})
this.projects = response.data


//loading screen
setTimeout(() => {
  
  this.loaded = false;
}, 1500);

   
  },
};
</script>

<style >
.custom-cursor{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
}
</style>
